import React, { useEffect, useState } from "react";
import './projects.css'
import '../../components/portfolio/portfolio.css'

const Projects = () => {

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        fetch('https://api.saadahmedev.com/api/project/all')
            .then((response) => response.json())
            .then((data) => setProjects(data.reverse()))
            .catch((error) => console.error('Error fetching projects:', error));
    }, []);

    const Code = ({codes}) => {
        return (
            <>
                {codes.map((code, index) => (
                    <p key={index}>{code}</p>
                ))}
            </>
        )
    }

    const Description = ({description}) =>  {
        return (
            <>
                {
                    description.map((line, index) => (
                        <>
                            <p key={index}>○ {line}</p>
                            <br/>
                        </>
                    ))
                }
            </>
        )
    }

    const Project = ({name, codes, image, description, projectUrl}) => {
        return (
            <div className="portfolio_content_container" onClick={() => onProjectClicked(projectUrl)}>
                <img className='portfolio_content_container_image' src={image} alt=''/>

                <div className="portfolio_content_container_details">
                    <p className="portfolio_content_container_name">{name}</p>

                    <div className='portfolio_content_container_codes'>
                        <Code codes={codes}/>
                    </div>

                    <div className={'portfolio_content_container_description'}>
                        <Description description={description} />
                    </div>
                </div>
            </div>
        )
    }

    const rootPaddingTop = '5rem'

    return (
        <div className='projects'>
            <div className='portfolio' style={{paddingTop: rootPaddingTop}}>
                {
                    projects
                        .map((project) => (
                            <Project name={project.projectName} codes={project.projectCodes.split('-')} image={project.projectImage} description={project.projectDescription.split('<br>')} projectUrl={project.projectUrl} />
                        ))
                }
            </div>
        </div>
    )
}

const onProjectClicked = (projectUrl) => {
    const anchor = document.createElement('a');
    anchor.href = projectUrl
    anchor.target = '_blank'
    anchor.click();
}

export default Projects