import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from "./pages/home/Home";
import Projects from "./pages/projects/Projects";

import './app.css'
import './index.css'

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/projects" element={<Projects />} />
            </Routes>
        </Router>
    )
}

export default App