import React, {useEffect} from "react";

import { FaLinkedin, FaGithub, FaFacebook, FaStackOverflow } from "react-icons/fa6";

import './contact.css'

const Contact = () => {

    useEffect(() => {
        // Remove the fragment identifier from the URL
        if (window.location.hash) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    const Social = ({Logo, link}) => {
        return (
            <a href={link} target='_blank' rel='noreferrer'><Logo /></a>
        )
    }

    return (
        <div className='contact' id={'contact'}>
            <div className="contact_container">
                <div className="contact_left-container">
                    <p className={'contact_left-container_heading'}>Let's make something amazing together</p>
                    <div className={'contact_left-container_heading-saying'}>
                        <p className={'contact_left-container_heading'}>Start by</p>
                        <p className={'contact_left-container_saying'} onClick={ () => window.location.href = 'mailto:saadahmedsoft@gmail.com'}>saying hi</p>
                    </div>

                    {/*<div className="contact__content">*/}
                    {/*    <h3 className="contact__title">Send a message</h3>*/}

                    {/*    <form action="" className="contact__form">*/}
                    {/*        <div className="contact__form-div">*/}
                    {/*            <label htmlFor="" className="contact__form-tag">Name</label>*/}
                    {/*            <input type="text" placeholder="Enter your name" className="contact__form-input"/>*/}
                    {/*        </div>*/}

                    {/*        <div className="contact__form-div">*/}
                    {/*            <label htmlFor="" className="contact__form-tag">Subject</label>*/}
                    {/*            <input type="text" placeholder="Enter your subject" className="contact__form-input"/>*/}
                    {/*        </div>*/}

                    {/*        <div className="contact__form-div">*/}
                    {/*            <label htmlFor="" className="contact__form-tag">Email</label>*/}
                    {/*            <input type="email" placeholder="Enter your email" className="contact__form-input"/>*/}
                    {/*        </div>*/}

                    {/*        <div className="contact__form-div contact__form-area">*/}
                    {/*            <label htmlFor="" className="contact__form-tag">Message</label>*/}
                    {/*            <textarea name="" id="" cols="30" rows="10" placeholder="Write your message here..." className="contact__form-input"></textarea>*/}
                    {/*        </div>*/}

                    {/*        <button className="contact__form-div-button">Send Message</button>*/}
                    {/*    </form>*/}
                    {/*</div>*/}
                </div>

                <div className="contact_right-container">
                    <p className={'contact_right-container_heading'}>Information</p>

                    <p className={'contact_right-container_address'}>Agargaon, Dhaka - 1207, Bangladesh</p>

                    <p className={'contact_right-container_email'}>saadahmedsoft@gmail.com</p>
                    <p className={'contact_right-container_email'}>contact@saadahmedev.com</p>
                    <p className={'contact_right-container_email'}>+880 1794 784643</p>

                    <div className={'contact_right-container-tabs'}>
                        <a href={'#home'} onClick={(e) => scrollToTab(e, 'home')}>Home</a>
                        <a href={'#about'} onClick={(e) => scrollToTab(e, 'about')}>About</a>
                        <a href={'#clients'} onClick={(e) => scrollToTab(e, 'clients')}>Clients</a>
                        <a href={'#portfolio'} onClick={(e) => scrollToTab(e, 'portfolio')}>Portfolio</a>
                    </div>
                </div>
            </div>

            <div className="contact_footer-container">
                <div className="contact_footer-container_left">
                    <div className="contact_footer-name">
                        <p className={'contact_footer_first-name'}>Saad</p>
                        <p>Ahmed</p>
                    </div>

                    <p className="contact_footer-copyright">ⓒ Saad Ahmed. 2018-2023. All Rights Reserved</p>
                </div>

                <div className="contact_footer-container_right">
                    <Social Logo={ FaGithub } link={'https://github.com/saadahmedscse'}/>
                    <Social Logo={ FaLinkedin } link={'https://www.linkedin.com/in/saadahmedscse/'}/>
                    <Social Logo={ FaFacebook } link={'https://www.facebook.com/dev.saad.ahmed'}/>
                    <Social Logo={ FaStackOverflow } link={'https://stackoverflow.com/users/10610218/saad-ahmed'}/>
                </div>

            </div>
        </div>
    )
}

function scrollToTab(e, item) {
    e.preventDefault();

    // Scroll to the #example element smoothly
    const element = document.getElementById(item.toLowerCase());
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

export default Contact