import React from "react";
import {AiFillAndroid} from 'react-icons/ai'
import {BiCodeCurly, BiCodeAlt} from "react-icons/bi";

import './about.css'

const About = () => {

    const SkillItem = ({name, backgroundColor, color}) => {
        const textStyle = { backgroundColor : backgroundColor, color: color };

        return (
            <p className='about-items_item' style={textStyle}>{name}</p>
        )
    }

    const ServiceItem = ({serviceName, serviceDescription, projectsCount, Icon}) => {
        return (
            <div className='about_service-items'>
                <div className="about_service-item-row">
                    <p className='about_service-items_name'>{serviceName}</p>
                    <Icon className='about_service-items_icon' />
                </div>
                <p className='about_service-items_description'>{serviceDescription}</p>
                <p className='about_service-items_project_count'>{projectsCount}</p>
            </div>
        )
    }

    const white = 'var(--color-text-white)'
    const dark = 'var(--color-bg-dark)'

    const summeryOne = 'Experienced Native Android Application Developer | Strong Java, Kotlin, and Backend Technology Skills | Problem-Solving Enthusiast | MVVM Architecture | Rest API | Rx Java | Dagger-Hilt | Firebase | Google Map | Payment Gateway Integration | Backend Development with Java Spring Boot, Spring MVC, Spring Security, JPA, Hibernate, and MySQL | Java Microservices | AWS EC2 | AWS RDS | GCP | Azure'
    const summeryTwo = 'I am a seasoned Native Android Application Developer with five years of hands-on experience in crafting top-notch mobile applications. Proficient in Java and Kotlin core concepts, I have honed my problem-solving skills by solving over 400 challenges on LeetCode. My passion for technology drives me to stay updated with the latest trends and innovations in the industry.'
    const summerThree = 'Having mastered MVVM Architecture, Rest API, Rx Java, Socket programming, Dagger-Hilt, Firebase, Google Map integration, Payment Gateway, and SMS gateway integration, I have delivered outstanding Android applications with a seamless user experience.'
    const summerFour = 'Eager to expand my horizons, I have embarked on a journey into the world of Backend Technology. My dedication led me to learn Java Spring Boot, Spring MVC, Spring Security, JPA, Hibernate, and MySQL, allowing me to contribute to five successful backend portfolio. With a deep understanding of Java Microservices, AOP, AWS technologies like EC2 and RDS, GCP App Engine and Microsoft Azure App Service, I am well-equipped to tackle complex backend challenges.'
    const summeryFive = 'I thrive in dynamic environments and value collaboration with diverse teams to deliver exceptional results. As I embrace new opportunities in the backend domain, I am excited to bring my versatile skill set to the table and contribute to innovative solutions that make a real impact.'
    const summerySix = 'Let\'s connect and explore how we can collaborate on exciting portfolio in the realms of mobile and backend technology. Reach out to me for discussions on Android development, backend solutions, or any tech-related endeavors.'

    return (
        <div className='about' id='about'>
            <div className='about_content'>
                <div className="about_content_left">
                    <p className='about_heading'>Skills.</p>

                    <div className="about_skills-items">
                        <SkillItem name='Java' backgroundColor='#f89820' color={dark}/>
                        <SkillItem name='Kotlin' backgroundColor='#7F52FF' color={white}/>
                        <SkillItem name='JavaScript' backgroundColor='#F0DB4F' color={dark}/>
                        <SkillItem name='TypeScript' backgroundColor='#0076C6' color={white}/>
                        <SkillItem name='C' backgroundColor='#28348E' color={white}/>
                    </div>

                    <div className="about_skills-items">
                        <SkillItem name='Spring Boot' backgroundColor='#6AAD3D' color={dark}/>
                        <SkillItem name='React JS' backgroundColor='#5ED4F3' color={dark}/>
                        <SkillItem name='Android Development' backgroundColor='#78C257' color={dark}/>
                        <SkillItem name='Express JS' backgroundColor='#383838' color={white}/>
                        <SkillItem name='Node JS' backgroundColor='#90CA51' color={dark}/>
                    </div>

                    <div className="about_skills-items">
                        <SkillItem name='Firebase' backgroundColor='#FFCD36' color={dark}/>
                        <SkillItem name='MySQL' backgroundColor='#F29111' color={dark}/>
                        <SkillItem name='Oracle DB' backgroundColor='#DC2023' color={white}/>
                        <SkillItem name='PostgreSQL' backgroundColor='#31648C' color={white}/>
                        <SkillItem name='MongoDB' backgroundColor='#4BAA3D' color={dark}/>
                    </div>

                    <div className="about_skills-items">
                        <SkillItem name='Amazon Web Services' backgroundColor='#FF9C08' color={dark}/>
                        <SkillItem name='Google Cloud Console' backgroundColor='#4787F1' color={dark}/>
                        <SkillItem name='Microsoft Azure' backgroundColor='#165AA0' color={white}/>
                    </div>

                    <div className="about_skills-items">
                        <SkillItem name='Kafka' backgroundColor='#080808' color={white}/>
                        <SkillItem name='Microservices' backgroundColor='#71CC3B' color={dark}/>
                        <SkillItem name='Jenkins' backgroundColor='#E8CFB1' color={dark}/>
                        <SkillItem name='Docker' backgroundColor='#1C60E6' color={white}/>
                        <SkillItem name='Kubernetes' backgroundColor='#3871E6' color={white}/>
                    </div>

                    <div className="about_skills-service_item_container">
                        <p className='about_heading'>Services.</p>
                        <ServiceItem serviceName='Back-End' serviceDescription='Crafting digital landscapes from the shadows. 🚀' projectsCount='5 PROJECTS' Icon={BiCodeCurly} />
                        <ServiceItem serviceName='Front-End' serviceDescription='Bringing websites to life with a touch of magic. 🪄' projectsCount='2 PROJECTS' Icon={BiCodeAlt} />
                        <ServiceItem serviceName='Android Development' serviceDescription='Turning ideas into interactive Android adventures. 🌟' projectsCount='100+ PROJECTS' Icon={AiFillAndroid} />
                    </div>
                </div>

                <div className="about_content_right">
                    <p className='about_heading'>Introduce.</p>
                    <p className='about_content-introduction_heading'>Hello! I'm Saad Ahmed</p>
                    <p className='about_content-introduction_bio'>Every great software begins with an even better story</p>
                    <p className='about_content-introduction_summery'>{summeryOne}<br/><br/>{summeryTwo}<br/><br/>{summerThree}<br/><br/>{summerFour}<br/><br/>{summeryFive}<br/><br/>{summerySix}</p>
                </div>
            </div>
        </div>
    )
}

export default About