import React from "react";
import {About, Clients, Header, Navbar, Portfolio, Contact} from "../../components";

const Home = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <About />
            <Clients />
            <Portfolio />
            <Contact />
        </div>
    )
}

export default Home