import React, {useEffect, useState} from 'react'
import {RiMenu3Line, RiCloseLine} from "react-icons/ri";
import {FaGithub, FaLinkedin} from "react-icons/fa6";

import './navbar.css'
import {FiMail} from "react-icons/fi";

const Navbar = () => {

    const[isScrolled, setIsScrolled] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    useEffect(() => {
        // Remove the fragment identifier from the URL
        if (window.location.hash) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    const [toggleMenu, setToggleMenu] = useState(false)

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true)
        } else {
            setIsScrolled(false)
        }

        const sections = ['home', 'about', 'clients', 'portfolio', 'contact'];

        for (let i = 0; i < sections.length; i++) {
            const section = document.getElementById(sections[i]);
            const sectionTop = section.offsetTop;
            const sectionBottom = sectionTop + section.clientHeight;
            const scrollY = window.scrollY + window.innerHeight * 0.1; // Adjust for viewport height

            if (scrollY >= sectionTop && scrollY <= sectionBottom) {
                setSelectedTab(i);
                break;
            }
        }
    }

    const Menu = ({ items }) => {
        return (
            <>
                {items.map((item, index) => (
                    <a
                        key={index}
                        href={`#${item.toLowerCase()}`}
                        className={selectedTab === index ? 'active-tab' : ''}
                        onClick={(e) => scrollToTab(e, item)}
                    >
                        {selectedTab === index ? `< ${item} >` : item}
                    </a>
                ))}
            </>
        )
    }

    const SocialMenu = () => {
        return (
            <>
                <a className='navbar-links_social-item' target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/saadahmedscse/'>
                    <FaLinkedin className='navbar-links_container_social-icon'/>
                    <p className='navbar-links_container_social-text'>Linked In</p>
                </a>

                <a className='navbar-links_social-item' target='_blank' rel='noreferrer' href='https://github.com/saadahmedscse'>
                    <FaGithub className='navbar-links_container_social-icon'/>
                    <p className='navbar-links_container_social-text'>Github</p>
                </a>

                <FiMail className='navbar-links_social_email' onClick={ () => window.location.href = 'mailto:saadahmedsoft@gmail.com'} />
            </>
        )
    }

    return (
        <div className={`navbar ${isScrolled ? 'navbar-shadow' : ''}`} id='navbar'>
            <div className="navbar-links">
                <div className="navbar-links_logo">
                    <p className='navbar-links_logo_first'>Saad</p>
                    <p>Ahmed</p>
                </div>
                <div className="navbar-links_container">
                    <Menu items={['Home', 'About', 'Clients', 'Portfolio', 'Contact']} selectedTab={selectedTab} />
                </div>
            </div>
            <div className="navbar-social">
                <SocialMenu />
            </div>
            <div className="navbar-menu">
                {toggleMenu
                    ? <RiCloseLine className={'navbar-links_social_email'} onClick={ () => setToggleMenu(false)} />
                    : <RiMenu3Line className={'navbar-links_social_email'} onClick={ () => setToggleMenu(true)} />
                }
                {toggleMenu &&
                    <div className="navbar-menu_container scale-up-center">
                        <div className="navbar-menu_container-links">
                            <Menu items={['Home', 'About', 'Clients', 'Portfolio', 'Contact']} selectedTab={selectedTab} />
                        </div>
                        <div className="navbar-menu_container-links-social">
                            <SocialMenu />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

function scrollToTab(e, item) {
    e.preventDefault();

    // Scroll to the #example element smoothly
    const element = document.getElementById(item.toLowerCase());
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

export default Navbar