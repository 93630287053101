import React, { useState, useEffect } from "react";
import {SiReact} from "react-icons/si";
import {AiFillAndroid} from "react-icons/ai";

import './header.css'
import springBootColored from '../../assets/spring_boot.png'

const Header = () => {

    const [profileData, setProfileData] = useState(null);
    const [resumeData, setResumeData] = useState(null);

    useEffect(() => {
        fetch('https://api.saadahmedev.com/api/profile')
            .then((response) => response.json())
            .then((data) => setProfileData(data))
            .catch((error) => console.error('Error fetching profile data:', error));
    }, []);

    useEffect(() => {
        fetch('https://api.saadahmedev.com/api/resume')
            .then((response) => response.json())
            .then((data) => setResumeData(data))
            .catch((error) => console.error('Error fetching resume data:', error))
    }, []);

    const Designation = () => {
        return (
            <>
                <p>Back-End Developer</p>
                <p>Front-End Developer</p>
                <p>Android Developer</p>
            </>
        )
    }

    const resumeDownload = () => {
        const anchor = document.createElement('a');
        anchor.href = resumeData.resumeUrl;
        anchor.target = '_blank'
        anchor.click();
    }

    const Experience = () => {
        return (
            <>
                <div className='header_left-content_bottom-content'>
                    <p className='header_left-content_bottom-content-year'>5</p>
                    <p className='header_left-content_bottom-content-text'>YEARS<br/>EXPERIENCE</p>
                </div>

                <div className='header_left-content_bottom-content header_left-content_bottom-content-right'>
                    <p className='header_left-content_bottom-content-year'>127+</p>
                    <p className='header_left-content_bottom-content-text'>PROJECTS<br/>COMPLETED</p>
                </div>
            </>
        )
    }

    const headerDescription = 'In the intricate web of digital innovation, I am the silent force working tirelessly behind the scenes. I am a Backend Developer, the architect of the digital realm, and the guardian of data integrity. With a deep passion for coding, I transform complex algorithms and databases into seamless, user-friendly experiences.'

    return (
        <div className='header' id='home'>
            <div className="header_left-content">
                <div className="header_left-content_middle">
                    <div className="header_left-content_middle-designation">
                        <Designation />
                    </div>

                    <p className="header_left-content_middle-heading">Behind every great frontend,<br />There's a backend architect.</p>

                    <p className="header_left-content_middle-description">{headerDescription}</p>

                    <button onClick={resumeDownload}>GET RESUME</button>
                </div>

                <div className="header_left-content_bottom">
                    <Experience />
                </div>
            </div>

            <div className="header_right-content">
                <div className="header_right-content-background"/>
                { profileData && profileData.imageUrl && (
                    <img
                        className='header_right-content-profile'
                        src={profileData.imageUrl}
                        alt=''
                    />
                )}
                <SiReact className='header_right-content-react-icon' />
                <AiFillAndroid className='header_right-content-android-icon' />
                <img className='header_right-content-spring_boot-icon' src={springBootColored} alt=''/>
                <div className='header_right-content-dark_spot'/>
                <div className='header_right-content-green_spot'/>
            </div>
        </div>
    )
}

export default Header