import React from "react";

import './clients.css'
import clientTopseba from '../../assets/clients/client_topseba.png'
import clientDoctorBD from '../../assets/clients/client_doctor_bd.png'
import clientArotdar from '../../assets/clients/client_arotdar.png'
import clientCsn from '../../assets/clients/client_csn.png'
import clientMetaBox from '../../assets/clients/client_metabox.png'
import clientJhmGroup from '../../assets/clients/client_jhm_group.png'
import clientNkGroup from '../../assets/clients/client_nk_group.png'
import clientItVanilla from '../../assets/clients/client_it_vanilla.png'
import clientDinajpurStore from '../../assets/clients/client_dinajpur_store.png'

const Clients = () => {
    return (
        <div className='clients' id='clients'>
            <div className='clients_item'>
                <p><a href='https://topseba.com' target='_blank' rel='noreferrer'><img src={clientTopseba} alt='TopSeba'/></a></p>
                <p><a href='https://doctorbd.com.bd' target='_blank' rel='noreferrer'><img src={clientDoctorBD} alt='Doctor BD'/></a></p>
                <p><a href='https://arotdar.net' target='_blank' rel='noreferrer'><img src={clientArotdar} alt='Arotdar'/></a></p>
                <p><a href='https://play.google.com/store/apps/details?id=desk.cafcsn.it' target='_blank' rel='noreferrer'><img src={clientCsn} alt='CSN Help Desk'/></a></p>
                <p><a href='https://metaboxsoft.com/' target='_blank' rel='noreferrer'><img src={clientMetaBox} alt='MetaBox Softwares'/></a></p>
            </div>
            <div className='clients_item'>
                <p><a href='https://jhmgroup.info/' target='_blank' rel='noreferrer'><img src={clientJhmGroup} alt='JHM Group'/></a></p>
                <p><a href='https://www.nkgroup.com.bd/' target='_blank' rel='noreferrer'><img src={clientNkGroup} alt='NK Group'/></a></p>
                <p><a href='https://www.itvanilla.com/' target='_blank' rel='noreferrer'><img src={clientItVanilla} alt='IT Vanilla'/></a></p>
                <p><a href='https://dinajpurstore.com/' target='_blank' rel='noreferrer'><img src={clientDinajpurStore} alt='Dinajpur Store'/></a></p>
            </div>
        </div>
    )
}

export default Clients